import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { connect } from 'react-redux';
import './login.css';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';
import Alert from 'reactstrap/lib/Alert';
import { UserState } from '../store/user/types';

interface PropsFromDispatch {
    [key: string]: any,
};
interface myState {
    username: string,
    realm?: string,
    redirect?: string,
    user: UserState,
}
interface PropsFromState {
    user: UserState,
};
interface OwnProps {
}
type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class resetSuccess extends React.Component<AllProps, myState> {
    constructor(props: any) {
        super(props);
        this.state = {
            username: '',
            realm: 'ritas house',
            redirect: '',
            user: this.props.user,
        };
    }

    showMessage() {
        return (
            <Alert color="info">
                Your password has been reset, please login with your new password.
                <div className="d-flex justify-content-center">
                    <span className="text-center pt-1 font-weight-light">
                        <Link to="/login">Return to login screen</Link>
                    </span>
                </div>
                Please reach out to us if you are having issues.
            </Alert>
        );
    }

    render() {
        return (
            <Container className="Login">
                <h2>Password reset email has been sent.</h2>
                {this.showMessage()}
                <div className="d-flex justify-content-center">
                    <span className="text-center font-weight-light">
                        Don't have an account yet?  <Link to="/signup">Sign up for an account</Link>
                    </span>
                </div>
                <div className="d-flex justify-content-center">
                    <span className="text-center pt-1 font-weight-light">
                        <Link to="/login">Return to login screen</Link>
                    </span>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state: PropsFromState): OwnProps => ({
  user: state.user,
})

export default connect(mapStateToProps)(resetSuccess);